<!--
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-14 17:18:13
 * @Message: 关于我们
-->
<template>
    <div class="about">
        <!-- header pages -->
        <div class="header-pages">
            <div class="pages-title">
                <div class="container">
                    <h2>2014</h2>
                    <span>不忘初心 , 方能始终。十年之约 , 你我同行。</span>
                </div>
            </div>
        </div>
        <!-- end header pages -->

        <!-- about us -->
        <div class="about-us section about-us-pages">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="entry" data-aos="fade-right" data-aos-duration="1500">
                            <img src="../../assets/img/hero.png" alt="" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="entry" data-aos="fade-left" data-aos-duration="1500">
                            <span>底蕴丰厚</span>
                            <h2>客户涵盖企业、政务、教育、医疗、司法等领域</h2>
                            <p>
                                我们以擅长企业系统、业务平台和软硬结合系统的建设而自豪。凭借多年来丰富的经验和行业洞察，我们能够为各个行业提供全面的解决方案。
                            </p>
                            <p>
                                在企业系统领域，我们不仅能够根据客户需求定制开发各类信息管理系统，协同办公平台和客户关系管理系统，还能为企业提供强大的数据分析和报告功能，帮助他们更好地理清现状，预测未来并做出明智的决策。
                            </p>
                            <p>
                                业务平台方面，我们深入了解各行各业的特点和需求，能够为客户设计和开发符合其业务流程和需求的平台。
                            </p>
                            <p>
                                我们致力于与客户建立长期合作关系，并始终关注技术发展趋势和市场变化。通过持续的学习和不断的创新，我们将不懈地提升自身能力，以满足客户不断变化的需求，并致力于为他们提供卓越的解决方案和技术支持。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end about us -->
        <!-- about us -->
        <div class="about-us section about-us-pages our-mission">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="1500">
                            <span>理念真诚</span>
                            <h2>积极与客户协作，为客户落实价值</h2>
                            <p>
                                作为全方位解决方案提供商，我们以推动合作伙伴和客户的共同发展为己任。我们坚信只有在合作中才能实现真正的成功，并始终立足于共赢的价值观。
                            </p>
                            <p>
                                无论是与企业合作，为其建设具备强大竞争力的系统与平台；还是与政府部门合作，创建高效便利的电子政务平台；或者与教育机构密切合作，打造创新的在线学习管理系统，我们始终将共赢作为合作的核心目标。
                            </p>
                            <p>
                                我们以客户的成功为导向，在每个项目中都与客户紧密合作，倾听他们的需求和挑战，并根据其特定情况量身定制解决方案。我们致力于帮助客户提升效率、降低成本、增强竞争力，并与他们携手共同实现业务目标。
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6" data-aos="zoom-in" data-aos-duration="1500">
                        <div class="entry">
                            <img src="../../assets/img/about-us.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end about us -->
        <!-- about us -->
        <div class="about-us section about-us-pages our-mission">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6" data-aos="fade-right" data-aos-duration="1500">
                        <div class="entry">
                            <img src="../../assets/img/hero.png" alt="" />
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="entry" data-aos="fade-left" data-aos-duration="1500">
                            <span>锐意进取</span>
                            <h2>紧跟时代步伐，积极探索AI</h2>
                            <p>
                                作为一家紧跟时代步伐的公司，我们积极探索人工智能（AI）技术的应用，并将其融入到各个领域的解决方案中。我们深知AI在未来的巨大潜力，因此不断投入研发资源，以确保我们始终处于AI技术的前沿。
                            </p>
                            <p>
                                我们不断追寻先进技术和创新解决方案，致力于帮助客户充分利用人工智能的潜力，实现业务增长和价值创造。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end about us -->
        <!-- testimonial -->
        <div class="testimonial-work">
            <div class="container">
                <div class="title-section">
                    <span>团队构成</span>
                    <h2>团结、和谐、充满活力的专业团队</h2>
                </div>
                <div class="title-section">
                    <i class="fas fa-quote-left"></i>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="2000">
                            <p>
                                架构师，10余年资深行业精力。
                                <br />
                                2款大型产品完整架构，独立设计1款开发框架，完成省级办公平台、市级互联平台等大型项目的交付。
                                <br />
                                2家公司创始股东。
                                <br />
                                现任总经理，负责总体与商务。
                            </p>
                            <ul>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                            </ul>
                            <h5>杨成</h5>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="2000">
                            <p>
                                担任过3家公司总监、副总。
                                <br />
                                任职期间主持开发团队，负责核心产品。带领百人团队。
                                <br />
                                担任过2家公司创始股东，目前一家已在筹备上市。
                                <br />
                                现任研发副总，负责主持研发工作。
                            </p>
                            <ul>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                            </ul>
                            <h5>唐轫</h5>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="2000">
                            <p>
                                担任过2家公司总监、副总，专职人事、财务。
                                <br />
                                参与多款大型产品深度研发, 后端资深研发负责人。
                                <br />
                                担任过1家公司创始股东
                                <br />
                                现担任财务副总，负责人事、财务。
                            </p>
                            <ul>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                            </ul>
                            <h5>王帅</h5>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="2000">
                            <p>
                                架构师出身，10年工作经验。
                                <br />
                                负责过1个大型产品发布，2个大型项目交付。
                                <br />
                                担任过1家公司中层管理，负责前端团队。
                                <br />
                                现担任研发主管，负责研发日常管理。
                            </p>
                            <ul>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                            </ul>
                            <h5>杨治明</h5>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="entry" data-aos="zoom-in" data-aos-duration="2000">
                            <p>
                                7年工作经验，精通全栈，熟悉硬件技术。
                                <br />
                                担任过1家公司副总架构师，设计并主持多个关键引擎开发。
                                <br />
                                现担任架构师，负责技术的总体规划与落实。
                            </p>
                            <ul>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                                <li><i class="fa fa-star"></i></li>
                            </ul>
                            <h5>万园</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end testimonial -->
    </div>
</template>

<script>
export default {
    name: "about",
    mounted() {},
};
</script>

<style scoped>
.testimonial-work .col-md-4 {
    margin-bottom: 40px;
}
</style>
